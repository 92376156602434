import {
  ApolloQueryResult,
  OperationVariables,
  gql,
  useMutation,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import * as React from "react";

import {
  AcceptPaymentWithStoredCreditMutation,
  AcceptPaymentWithStoredCreditMutationVariables,
  CreditAccountFragmentFragment,
  CreditAccountQuery,
  CurrencyCode
} from "../../shared/generated/graphql";

export const creditAccountFragment = gql`
  fragment CreditAccountFragment on CreditAccount {
    uid
    openToBuy
    balance
    updatedAt
    status
  }
`;

export const creditAccountQuery = gql`
  query CreditAccount {
    creditBalance {
      ...CreditAccountFragment
    }
  }
  ${creditAccountFragment}
`;

export const acceptPaymentWithStoredCreditMutation = gql`
  mutation AcceptPaymentWithStoredCredit(
    $orderSlugId: ID!
    $amountCents: Int!
    $currencyCode: CurrencyCode
    $exchangeRate: Float
  ) {
    acceptPaymentWithStoredCredit(
      orderSlugId: $orderSlugId
      amountCents: $amountCents
      currencyCode: $currencyCode
      exchangeRate: $exchangeRate
    )
  }
`;

export function useCreditAccount(): {
  loading: boolean;
  creditAccount: CreditAccountFragmentFragment | null;
  error: Error | undefined;
  refetch: (
    variables?: OperationVariables
  ) => Promise<ApolloQueryResult<CreditAccountQuery>>;
  acceptPaymentWithStoredCredit: (
    orderSlugId: string,
    amountCents: number,
    currencyCode: CurrencyCode,
    exchangeRate: number
  ) => Promise<string>;
} {
  const [acceptPaymentWithStoredCredit] = useMutation<
    AcceptPaymentWithStoredCreditMutation,
    AcceptPaymentWithStoredCreditMutationVariables
  >(acceptPaymentWithStoredCreditMutation);

  const { loading, error, data, refetch } =
    useQueryWithPreviousData<CreditAccountQuery>(creditAccountQuery, {
      fetchPolicy: "network-only"
    });

  const acceptPaymentWithStoredCreditWrapped = React.useCallback(
    async (
      orderSlugId: string,
      amountCents: number,
      currencyCode: CurrencyCode,
      exchangeRate: number
    ) => {
      const { data } = await acceptPaymentWithStoredCredit({
        variables: { orderSlugId, amountCents, currencyCode, exchangeRate }
      });
      if (!data?.acceptPaymentWithStoredCredit) {
        throw new Error("Data is not defined?");
      }
      return data.acceptPaymentWithStoredCredit;
    },
    [acceptPaymentWithStoredCredit]
  );

  return {
    loading,
    error,
    creditAccount: data?.creditBalance ?? null,
    refetch,
    acceptPaymentWithStoredCredit: acceptPaymentWithStoredCreditWrapped
  };
}

export function useCreditAccountCached(skip = false): {
  loading: boolean;
  creditAccount: CreditAccountFragmentFragment | null;
  error: Error | undefined;
} {
  const { isLoggedIn } = useSession();
  const { loading, error, data } = useQueryWithPreviousData<CreditAccountQuery>(
    creditAccountQuery,
    { skip: !isLoggedIn || skip, context: { batchKey: "CreditAccounts" } }
  );
  return {
    loading,
    error,
    creditAccount: data?.creditBalance ?? null
  };
}
