import { readNonZeroNaturalNumber } from "@outschool/environment";
import { City } from "@outschool/iplookup-client";
import { BASE_LOCALE, I18nLocale } from "@outschool/localization";
import type { OsEnvironment } from "@outschool/platform";

type IpLookupInfoType = City | undefined;

export type OutschoolServer = {
  OUTSCHOOL_ENV: string;
  FILESTACK_APP_KEY: string;
  SECURED_FILESTACK_APP_KEY: string;
  SECURED_FILESTACK_APP_SECRET: string;
  SECURED_FILESTACK_APP_STORE_PICK_POLICY_2032_08_15: string;
  SECURED_FILESTACK_APP_STORE_PICK_SIGNATURE_2032_08_15: string;
  FEATURE_FLAGS: FeatureFlags;
  ENABLED_FEATURE_FLAGS: string[];
  APP_NAME: string;
  COMMIT_SHA: string;
  STRIPE_PUBLISHABLE_KEY: string;
  STRIPE_WEBHOOK_SIG: string;
  SEGMENT_WRITE_KEY: string;
  IS_PRERENDER_REQUEST: boolean;
  IS_BOT_REQUEST: boolean;
  PODSIGHT_PIXEL_KEY: string;
  LEARNER_HOSTNAME: string;
  APP_HOSTNAME: string;
  IS_LANGJS_APP: boolean;
  IS_REVIEW_APP: boolean;
  IPLOOKUP_API_KEY: string;
  IPLOOKUP_API_BASE_URL: string;
  KAFKA_REST_PROXY: string;
  GBP_EXCHANGE_RATE: string;
  APPLE_SIGNIN_CLIENT_ID: string;
  FACEBOOK_APP_ID: string;
  GOOGLE_CLIENT_ID: string;
  ORDERS_EMAIL_PREVIEW_URL: string;
  CLASSROOM_EMAIL_PREVIEW_URL: string;
  ACCOUNT_MANAGEMENT_EMAIL_PREVIEW_URL: string;
  CONTENT_MODERATION_EMAIL_PREVIEW_URL: string;
  EXPERIMENTS_SERVICE_URL: string;
  EXPERIMENTS_API_KEY: string;
  EXPERIMENT_ASSIGNMENTS: Record<string, string>;
  IPLOOKUP_INFO: City | undefined;
  NOTIFICATIONS_SERVICE_URL: string;
  REFRESH_SESSION_TOKEN_REPEATER_DISABLED: string;
  TRANSLATION_SERVICE_URL: string;
  MUX_DATA_ENV_KEY: string;
  CLIENT_VERSION: number;
  CLASSWALLET_CALLBACK_URL: string;
  CLASSWALLET_VENDOR_ID: string;
  LOCALE: I18nLocale;
  UI_ANALYTICS_DEBUG: string;
  UI_ANALYTICS_DEV_MODE: string;
  UI_ANALYTICS_DISABLED_INTEGRATION_LIST: string;
  UI_ANALYTICS_EVENT_QUEUE_LIMIT: string;
  UI_ANALYTICS_LOAD_TIME_LIMIT: string;
  CLOUDFLARE_BOT_SCORE: string;
  CLOUDFLARE_BOT_VERIFIED: boolean;
  CLOUDFLARE_THREAT_SCORE: string;
  SEARCH_LISTINGS_RATE_LIMIT_PER_MINUTE: number;
  USE_PRODUCT_FEED_EXPORT_V2: boolean;
  GOOGLE_AUTH_DISALLOW_WEBVIEW: boolean;
  GQL_BLACKLIST: string[];
  GRANDFATHER_DATE: string;
  IS_READ_ONLY_MODE: boolean;
  PLATFORM_ENV: OsEnvironment;
  FF_ENABLE_FIRST_CC_USE_CHECK: boolean;
  TNS_POINTS_SUSPENSION_THRESHOLD: number;
};

// OUTSCHOOL_SERVER is set in marketplaceApp.tsx, and used by the client.
const OUTSCHOOL_SERVER: OutschoolServer = (global as any).OUTSCHOOL_SERVER;

// OUTSCHOOL_TEST_INIT is set in tests/init.js to signal we are in a test
const OUTSCHOOL_TEST_INIT: boolean = !!(global as any).OUTSCHOOL_TEST_INIT;

// isBrowser means, we should be in the browser.
// TODO: We should revisit this assumption when we work on server side
//       rendering
export const isBrowser = !!OUTSCHOOL_SERVER && !OUTSCHOOL_TEST_INIT;
export const isNode = !isBrowser;

export type Environment = "production" | "staging" | "development" | "test";
export const OUTSCHOOL_ENV = ((isNode && process.env.OUTSCHOOL_ENV) ||
  (isBrowser && OUTSCHOOL_SERVER.OUTSCHOOL_ENV) ||
  "development") as Environment;

export const APP_NAME: string | undefined = isNode
  ? process.env.LANGJS_ENVNAME ?? "devel"
  : OUTSCHOOL_SERVER.APP_NAME;

export const COMMIT_SHA: string | undefined = isNode
  ? process.env.SOURCE_VERSION || process.env.GIT_COMMIT
  : OUTSCHOOL_SERVER.COMMIT_SHA;

export const isDebug =
  isNode &&
  process.execArgv &&
  (process.execArgv.indexOf("--debug-brk") !== -1 ||
    process.execArgv.indexOf("--debug") !== -1);

export function shouldUseSQS() {
  return !process.env.NO_TASK_QUEUES;
}

export const IPLOOKUP_API_KEY =
  (isNode && process.env.IPLOOKUP_API_KEY) ||
  (isBrowser && OUTSCHOOL_SERVER.IPLOOKUP_API_KEY) ||
  undefined;

export const isPrerenderRequest =
  isBrowser && !!OUTSCHOOL_SERVER.IS_PRERENDER_REQUEST;
export const isProduction = OUTSCHOOL_ENV === "production";
export const isStaging = OUTSCHOOL_ENV === "staging";
export const isTest = OUTSCHOOL_ENV === "test";
export const isDevelopment = OUTSCHOOL_ENV === "development";
export const isWebpackDevServer = isNode && !!process.env.WEBPACK_DEV_SERVER;
export const isLangjsApp =
  (isNode && !!process.env.LANGJS_ENVNAME) ||
  (isBrowser && OUTSCHOOL_SERVER.IS_LANGJS_APP) ||
  false;
export const isReviewApp =
  (isNode && process.env.IS_REVIEW_APP === "true") ||
  (isBrowser && OUTSCHOOL_SERVER.IS_REVIEW_APP) ||
  false;

export const GITLAB_ENV_NAME = isNode ? process.env.GITLAB_ENV_NAME : null;
export const IPLOOKUP_API_BASE_URL =
  (isNode && process.env.IPLOOKUP_API_BASE_URL) ||
  (isBrowser && OUTSCHOOL_SERVER.IPLOOKUP_API_BASE_URL) ||
  (isProduction ? "iplookup.app.outschool.com" : null) ||
  // review-apps default to staging version
  (isTest || isDevelopment ? null : "master-iplookup.review.outschool.dev");

export const redirectCalendars = isStaging || isProduction;

export const LEARNER_HOSTNAME = isBrowser
  ? OUTSCHOOL_SERVER.LEARNER_HOSTNAME
  : process.env.LEARNER_HOSTNAME;

export const APP_HOSTNAME = isBrowser
  ? OUTSCHOOL_SERVER.APP_HOSTNAME
  : process.env.APP_HOSTNAME;

export const MUX_CLASS_RECORDING_TOKEN_ID =
  process.env.MUX_CLASS_RECORDING_TOKEN_ID;
export const MUX_CLASS_RECORDING_TOKEN_SECRET =
  process.env.MUX_CLASS_RECORDING_TOKEN_SECRET;

export const MUX_DATA_ENV_KEY = isBrowser
  ? OUTSCHOOL_SERVER.MUX_DATA_ENV_KEY
  : process.env.MUX_DATA_ENV_KEY;

export const LEARNER_TRACKING_HOSTNAME = isProduction
  ? "https://learner-tracking.outschool.com"
  : isStaging
  ? "https://learner-tracking.outschool.dev"
  : "http://learner-tracking.outschool.test";

/**
 * Should be unique per branch and always constant on production
 */
export const CONSUMER_GROUP = process.env.CONSUMER_GROUP;

export const KAFKA_API_KEY = process.env.KAFKA_API_KEY || "";
export const KAFKA_API_SECRET = process.env.KAFKA_API_SECRET || "";
export const KAFKA_BROKER_URL = process.env.KAFKA_BROKER_URL || "";
/**
 * Concurrency to use for Kafka
 */
export const KAFKA_CONCURRENCY = process.env.KAFKA_CONCURRENCY
  ? Number.parseInt(process.env.KAFKA_CONCURRENCY, 10)
  : 5;
export const KAFKA_REST_PROXY = isProduction
  ? "https://events.outschool.com"
  : "https://kafka-rest-proxy-stage.outschool.dev";

export const SCHEMA_REGISTRY_URL = process.env.SCHEMA_REGISTRY_URL || "";
export const SCHEMA_REGISTRY_API_KEY =
  process.env.SCHEMA_REGISTRY_API_KEY || "";
export const SCHEMA_REGISTRY_API_SECRET =
  process.env.SCHEMA_REGISTRY_API_SECRET || "";
export const SOCIAL_TOPICS_BASIC_AUTH_TOKEN =
  process.env.SOCIAL_TOPICS_BASIC_AUTH_TOKEN;

const TRUSTOS_PORT = process.env.TRUSTOS_PORT || 4010;
export const TRUSTOS_URL =
  process.env.TRUSTOS_URL ||
  (isProduction
    ? "https://trustos.app.outschool.com/graphql"
    : isStaging
    ? "https://master-trustos.review.outschool.dev/graphql"
    : `http://0.0.0.0:${TRUSTOS_PORT}/graphql`);

export const ADMIN_CREDIT_MAX_AMOUNT_DOLLARS = process.env
  .ADMIN_CREDIT_MAX_AMOUNT_DOLLARS
  ? Number.parseInt(process.env.ADMIN_CREDIT_MAX_AMOUNT_DOLLARS)
  : 50;
export const ADMIN_CREDIT_MIN_AMOUNT_DOLLARS = process.env
  .ADMIN_CREDIT_MIN_AMOUNT_DOLLARS
  ? Number.parseInt(process.env.ADMIN_CREDIT_MIN_AMOUNT_DOLLARS)
  : 1;
export const ADMIN_CREDIT_CONFIRMATION_PHRASE =
  process.env.ADMIN_CREDIT_CONFIRMATION_PHRASE || "trust";

/**
 * GitLab feature flag names
 * @deprecated use a string literal instead of this enum
 */
export enum FeatureFlag {
  SchoolReliefProgram = "partners-school-relief-program",
  NewAutoScheduleFeature = "educators-auto-schedule-feature",
  LearnersClubPostScores = "learners-club-post-scores",
  ShowUnusedWalletFunds = "partners-show-unused-wallet-funds",
  BuyerOrgAdminMode = "partners-buyer-org-admin-mode",
  TranslateSiteCopy = "international-translate-site-copy",
  ActivityPageMobileExperiment = "educators-activity-page-mobile-experiment",
  SitemapProviderCategories = "new-families-sitemap-provider-categories",
  SitemapProviderContentful = "new-families-sitemap-provider-contentful",
  SitemapProviderGhost = "new-families-sitemap-provider-ghost",
  LearnersSummerCampoutPromoBanner = "learners-summer-campout-promo-banner",
  SellerOrgPin = "educators-seller-org-pin",
  LimitGroupLottieAnimations = "limit-group-lottie-animations"
}

const FEATURE_FLAGS_SERVER_ONLY = {
  // --- Optional configuration variables for development ---
  // Override the learner app URL on review apps or during local development.
  LEARNER_HOSTNAME: process.env.LEARNER_HOSTNAME,
  // Allows review apps or local development to always show the experiment
  // override UI, even for non-admins or unauthenticated users.
  EXPERIMENT_OVERRIDE: process.env.FF_EXPERIMENT_OVERRIDE === "enabled",
  DISABLE_OPTIONAL_QUERIES: process.env.FF_DISABLE_OPTIONAL_QUERIES,
  ENABLE_PRERENDER: process.env.FF_ENABLE_PRERENDER,
  DEBUG_TRACKING: process.env.FF_DEBUG_TRACKING,
  SAVED_CLASSES_DIGEST_EMAIL_ROLLOUT:
    process.env.FF_SAVED_CLASSES_DIGEST_EMAIL_ROLLOUT,
  CONTENT_MODERATION: process.env.FF_CONTENT_MODERATION === "enabled",
  CURRENCY_ENROLLMENT: process.env.FF_CURRENCY_ENROLLMENT === "enabled",
  NEW_USER_WELCOME_EMAIL_ROLLOUT: process.env.FF_NEW_USER_WELCOME_EMAIL_ROLLOUT,
  IOS_LAUNCH_PROMO: process.env.FF_IOS_LAUNCH_PROMO,
  LEARNER_SUPPORT_EXPERIMENT_ROLLOUT:
    process.env.FF_LEARNER_SUPPORT_EXPERIMENT_ROLLOUT,
  PARENT_ABANDONED_FAVORITE_EMAIL_ROLLOUT:
    process.env.FF_PARENT_ABANDONED_FAVORITE_EMAIL_ROLLOUT === "enabled",
  SHOW_SIMILAR_CLASSES_ABOVE_ROLLOUT:
    process.env.FF_SHOW_SIMILAR_CLASSES_ABOVE_ROLLOUT,
  EMAIL_TEACHERS_YOU_FOLLOW_REMIX_ROLLOUT:
    process.env.FF_EMAIL_TEACHERS_YOU_FOLLOW_REMIX_ROLLOUT,
  EMAIL_TEACHERS_YOU_FOLLOW_REMIX_ENABLED:
    process.env.FF_EMAIL_TEACHERS_YOU_FOLLOW_REMIX_ENABLED,
  PARENT_MEETING_REMINDER_UPSELL_ROLLOUT:
    process.env.FF_PARENT_MEETING_REMINDER_UPSELL_ROLLOUT,
  COOKIE_CONSENT: process.env.FF_COOKIE_CONSENT === "enabled",
  EMAIL_PARENT_ONGOING_RENEWAL_ROLLOUT:
    process.env.FF_EMAIL_PARENT_ONGOING_RENEWAL_ROLLOUT,
  REMIX_BLAST_EMAILS_ROLLOUT: process.env.FF_REMIX_BLAST_EMAILS_ROLLOUT,
  PROMOTED_SEARCH_LOGGING: process.env.FF_PROMOTED_SEARCH_LOGGING === "enabled",
  REFRESH_ERROR_REPORTING_TOGGLE:
    process.env.FF_REFRESH_ERROR_REPORTING_TOGGLE === "enabled",
  LEARNER_APP_FPS_TRACKING:
    process.env.FF_LEARNER_APP_FPS_TRACKING === "enabled",
  REFRESH_ERROR_USER_UIDS: parseJsonSafely(
    process.env.FF_REFRESH_ERROR_USER_UIDS,
    [] as string[]
  ),
  ITERABLE_DATAFEED: process.env.FF_ITERABLE_DATAFEED === "enabled",
  GHOST_CONTENT_KEY: process.env.GHOST_CONTENT_KEY,
  CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
  SEARCH_LISTINGS_ADMIN_TAGS:
    process.env.FF_SEARCH_LISTINGS_ADMIN_TAGS === "enabled",
  LOG_MISSING_REFRESH_TOKEN:
    isTest ||
    isDevelopment ||
    process.env.FF_LOG_MISSING_REFRESH_TOKEN === "enabled",
  IS_LATE_ENROLL_FOR_FIXED_LENGTH_CLASSES_SEARCH_ENABLED:
    isTest ||
    isDevelopment ||
    process.env.FF_LATE_ENROLL_FOR_FIXED_LENGTH_CLASSES_SEARCH === "enabled"
};

export const LOCALE = isBrowser ? OUTSCHOOL_SERVER.LOCALE : BASE_LOCALE;
export const ENABLE_LOCALE_PSEUDOLOCALIZATION = !isProduction; // Add more conditions as needed
export const DEFAULT_PSEUDOLOCALIZATION_REPEAT_COUNT = 2;

/** Environment variable feature flag values */
export const FEATURE_FLAGS = isBrowser
  ? OUTSCHOOL_SERVER.FEATURE_FLAGS
  : FEATURE_FLAGS_SERVER_ONLY;

function parseJsonSafely<T>(str: string | undefined, fallback: T) {
  if (!str) {
    return fallback;
  }

  try {
    const json = JSON.parse(str);

    return json as T;
  } catch (_) {
    return fallback;
  }
}

type FeatureFlags = typeof FEATURE_FLAGS_SERVER_ONLY;
export type FeatureFlagKey = keyof FeatureFlags;
export type FeatureFlagKeyWithStringValue = {
  [K in FeatureFlagKey]: FeatureFlags[K] extends string | boolean | undefined
    ? K
    : never;
}[FeatureFlagKey];

export const ENABLED_FEATURE_FLAGS = isBrowser
  ? OUTSCHOOL_SERVER.ENABLED_FEATURE_FLAGS
  : [];

export const clientVersion = isBrowser
  ? OUTSCHOOL_SERVER.CLIENT_VERSION
  : readNonZeroNaturalNumber(process.env.CLIENT_VERSION) ?? 419;

const batchSize = Number.parseInt(
  process.env.DATALOADER_BATCH_SIZE ?? "not a number",
  10
);
export const DATALOADER_BATCH_SIZE = isNaN(batchSize) ? 20 : batchSize;

const STRIPE_TEST_PUBLISHABLE_KEY =
  "pk_test_516ULSSI4hzsXqJKOCwoZfuRFSfNWOrPE1pWVDKOwmGamBurl9YXQxEk9p0JJKrg9oepzu7TgRlAtIPh2lbOUfi6400XlAVgYSR";
const STRIPE_TEST_SECRET_KEY =
  "sk_test_516ULSSI4hzsXqJKOUDef1HLPeTTBaw7efrKr6ckBEG9uRF8cH614hIqBnjhzNEE97TvwefrtAOFOjQFK7IN1AuaY00cHoR92pE";

export const STRIPE_PUBLISHABLE_KEY =
  isProduction || isStaging
    ? isBrowser
      ? (global as any).OUTSCHOOL_SERVER.STRIPE_PUBLISHABLE_KEY
      : process.env.STRIPE_PUBLISHABLE_KEY
    : isTest
    ? undefined
    : STRIPE_TEST_PUBLISHABLE_KEY;

export const STRIPE_SECRET_KEY =
  isProduction || isStaging
    ? process.env.STRIPE_SECRET_KEY
    : isTest
    ? undefined
    : STRIPE_TEST_SECRET_KEY;

/**
 * Setup your local webhook: https://docs.stripe.com/webhooks
 */
export const STRIPE_WEBHOOK_SIG = process.env.STRIPE_WEBHOOK_SIG || "";

export const STRIPE_API_VERSION = "2020-08-27";

export const SEGMENT_WRITE_KEY = isNode
  ? process.env.SEGMENT_WRITE_KEY
  : OUTSCHOOL_SERVER.SEGMENT_WRITE_KEY;
export const GBP_EXCHANGE_RATE =
  (isNode && process.env.GBP_EXCHANGE_RATE) ||
  (isBrowser && OUTSCHOOL_SERVER.GBP_EXCHANGE_RATE) ||
  undefined;

/**
 * The service ID bundle to use to authorize.
 * You can add new service IDs here:
 * https://developer.apple.com/account/resources/identifiers/list/serviceId
 */
export const APPLE_SIGNIN_CLIENT_ID = isBrowser
  ? (global as any).OUTSCHOOL_SERVER.APPLE_SIGNIN_CLIENT_ID
  : process.env.APPLE_SIGNIN_CLIENT_ID;

/**
 * You can get this from:
 * https://developer.apple.com/account/#!/membership
 */
export const APPLE_DEVELOPER_TEAM_ID = process.env.APPLE_DEVELOPER_TEAM_ID;

/**
 * This should be the key ID of APPLE_SIGNIN_SECRET.
 * @see https://developer.apple.com/account/resources/authkeys/list
 */
export const APPLE_SIGNIN_KEY_ID = process.env.APPLE_SIGNIN_KEY_ID;

/**
 * The private key for APPLE_SIGNIN_KEY_ID
 * @see https://developer.apple.com/account/resources/authkeys/list
 */
export const APPLE_SIGNIN_SECRET = process.env.APPLE_SIGNIN_SECRET;

/**
 * The bundle id for the iOS Learner App
 */
export const IOS_LEARNER_APP_BUNDLE_ID = process.env.IOS_LEARNER_APP_BUNDLE_ID;

/**
 * The app id for Facebook.
 * @see https://developers.facebook.com/apps
 */
export const FACEBOOK_APP_ID = isBrowser
  ? OUTSCHOOL_SERVER.FACEBOOK_APP_ID
  : process.env.FACEBOOK_APP_ID;

export const GOOGLE_CLIENT_ID = isBrowser
  ? OUTSCHOOL_SERVER.GOOGLE_CLIENT_ID
  : process.env.GOOGLE_CLIENT_ID;

export const GOOGLE_CLIENT_ID_IOS = process.env.GOOGLE_CLIENT_ID_IOS;

export const GOOGLE_CLIENT_ID_ANDROID = process.env.GOOGLE_CLIENT_ID_ANDROID;

export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET;

export const ORDERS_EMAIL_PREVIEW_URL = isBrowser
  ? OUTSCHOOL_SERVER.ORDERS_EMAIL_PREVIEW_URL
  : process.env.ORDERS_EMAIL_PREVIEW_URL;

export const CLASSROOM_EMAIL_PREVIEW_URL = isBrowser
  ? OUTSCHOOL_SERVER.CLASSROOM_EMAIL_PREVIEW_URL
  : process.env.CLASSROOM_EMAIL_PREVIEW_URL;

export const ACCOUNT_MANAGEMENT_EMAIL_PREVIEW_URL = isBrowser
  ? OUTSCHOOL_SERVER.ACCOUNT_MANAGEMENT_EMAIL_PREVIEW_URL
  : process.env.ACCOUNT_MANAGEMENT_EMAIL_PREVIEW_URL;

export const CONTENT_MODERATION_EMAIL_PREVIEW_URL = isBrowser
  ? OUTSCHOOL_SERVER.CONTENT_MODERATION_EMAIL_PREVIEW_URL
  : process.env.CONTENT_MODERATION_EMAIL_PREVIEW_URL;

export const EXPERIMENTS_SERVICE_URL = isBrowser
  ? OUTSCHOOL_SERVER.EXPERIMENTS_SERVICE_URL
  : process.env.EXPERIMENTS_SERVICE_URL ||
    (isTest && "http://experiments-test.url");

export const EXPERIMENTS_API_KEY =
  (isBrowser
    ? OUTSCHOOL_SERVER.EXPERIMENTS_API_KEY
    : process.env.EXPERIMENTS_API_KEY) ?? "experiments-api-key";

const experimentAssignmentsMaxReqTimeMs = readNonZeroNaturalNumber(
  process.env.EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS
);
export const EXPERIMENT_ASSIGNMENTS_MAX_REQ_TIME_MS =
  experimentAssignmentsMaxReqTimeMs ?? 500;

export const EXPERIMENT_ASSIGNMENTS = OUTSCHOOL_SERVER?.EXPERIMENT_ASSIGNMENTS;

/**
 * Controls the number of days in advance that we'll update zoom meeting titles
 * to reflect changes in the activity title.
 */
export const LIMITED_DAYS_TO_UPDATE_ZOOM_MEETING_TITLES = Number.parseInt(
  process.env.LIMITED_DAYS_TO_UPDATE_ZOOM_MEETING_TITLES ?? "3"
);

// ClassWallet environment required on the front-end.
export const CLASSWALLET_CALLBACK_URL =
  (isBrowser
    ? OUTSCHOOL_SERVER.CLASSWALLET_CALLBACK_URL
    : process.env.CLASSWALLET_CALLBACK_URL) ||
  "gillin-esa-one-on-one-website.review.outschool.dev";

export const CLASSWALLET_VENDOR_ID =
  (isBrowser
    ? OUTSCHOOL_SERVER.CLASSWALLET_VENDOR_ID
    : process.env.CLASSWALLET_VENDOR_ID) || "62ebde34c70c1833db194316";

export const NOTIFICATIONS_SERVICE_URL =
  (isBrowser
    ? OUTSCHOOL_SERVER.NOTIFICATIONS_SERVICE_URL
    : process.env.NOTIFICATIONS_SERVICE_URL) ?? "http://localhost:3023";

export const TRANSLATION_SERVICE_URL = isBrowser
  ? OUTSCHOOL_SERVER.TRANSLATION_SERVICE_URL
  : process.env.TRANSLATION_SERVICE_URL ?? "http://translations.outschool.com";

export const REFRESH_SESSION_TOKEN_REPEATER_DISABLED = isBrowser
  ? !!OUTSCHOOL_SERVER.REFRESH_SESSION_TOKEN_REPEATER_DISABLED
  : process.env.REFRESH_SESSION_TOKEN_REPEATER_DISABLED;

/**
 * Whether or not it is running in gitlab CI
 *
 */
export const IS_GITLAB_CI = Boolean(process.env.GITLAB_CI);

export const isTestLocalEnv = isTest && !IS_GITLAB_CI;

/**
 * Throws an error if env variable doesn't exist in required environments
 * Default will **only** be provided in non-required environments
 *
 * Params:
 * - requiredEnvironments defaults to ['staging', 'production']
 */
export function getRequiredSecret(
  envVarName: string,
  defaultValue: string,
  requiredEnvironments: Environment[] = ["staging", "production"]
) {
  if (process.env[envVarName]) {
    return process.env[envVarName];
  }

  if (requiredEnvironments.includes(OUTSCHOOL_ENV)) {
    throw Error(
      `Required secret, (process.env.${envVarName}), was not defined`
    );
  }

  return defaultValue;
}

const TEST_ITERABLE_DATAFEED_SECRET = "15faea23-24c7-4f92-b50c-0b964174e032";
export const ITERABLE_DATAFEED_SECRET =
  isDevelopment || isTest
    ? TEST_ITERABLE_DATAFEED_SECRET
    : process.env.ITERABLE_DATAFEED_SECRET;

export const PRERENDER_LATER = process.env.PRERENDER_LATER || false;

export const IPLOOKUP_INFO: IpLookupInfoType = isBrowser
  ? OUTSCHOOL_SERVER.IPLOOKUP_INFO
  : undefined;

/**
 * Sitemap Tasks
 */
export const GHOST_CONTENT_KEY = process.env.GHOST_CONTENT_KEY;
export const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID;
export const CONTENTFUL_PREVIEW_TOKEN = process.env.CONTENTFUL_PREVIEW_TOKEN;

export const UI_ANALYTICS_DEBUG = isBrowser
  ? OUTSCHOOL_SERVER.UI_ANALYTICS_DEBUG
  : process.env.UI_ANALYTICS_DEBUG;
export const UI_ANALYTICS_DEV_MODE = isBrowser
  ? OUTSCHOOL_SERVER.UI_ANALYTICS_DEV_MODE
  : process.env.UI_ANALYTICS_DEV_MODE;
export const UI_ANALYTICS_DISABLED_INTEGRATION_LIST = isBrowser
  ? OUTSCHOOL_SERVER.UI_ANALYTICS_DISABLED_INTEGRATION_LIST
  : process.env.UI_ANALYTICS_DISABLED_INTEGRATION_LIST;
export const UI_ANALYTICS_EVENT_QUEUE_LIMIT = isBrowser
  ? OUTSCHOOL_SERVER.UI_ANALYTICS_EVENT_QUEUE_LIMIT
  : process.env.UI_ANALYTICS_EVENT_QUEUE_LIMIT;
export const UI_ANALYTICS_LOAD_TIME_LIMIT = isBrowser
  ? OUTSCHOOL_SERVER.UI_ANALYTICS_LOAD_TIME_LIMIT
  : process.env.UI_ANALYTICS_LOAD_TIME_LIMIT;

export const ZOOM_REST_API_TIMEOUT_MS = process?.env?.ZOOM_REST_API_TIMEOUT_MS
  ? Number.parseInt(process.env.ZOOM_REST_API_TIMEOUT_MS)
  : 10000;
export const ZOOM_WEBHOOK_SECRET_TOKENS =
  process?.env?.ZOOM_WEBHOOK_SECRET_TOKENS;

export const TRUSTOS_PAYOUT_CHECKPOINT_PARALLELISM = process.env
  .TRUSTOS_PAYOUT_CHECKPOINT_PARALLELISM
  ? Number.parseInt(process.env.TRUSTOS_PAYOUT_CHECKPOINT_PARALLELISM)
  : 5;

export const SUSPENDED_USER_CACHE_UPDATE_FREQUENCY_MS = process.env
  .SUSPENDED_USER_CACHE_UPDATE_FREQUENCY_MS
  ? Number.parseInt(process.env.SUSPENDED_USER_CACHE_UPDATE_FREQUENCY_MS)
  : 1000 * 60 * 10;

export const TURNSTILE_SITE_KEY = process?.env.TURNSTILE_SITE_KEY;
export const TURNSTILE_SECRET = process?.env.TURNSTILE_SECRET;
export const TURNSTILE_THRESH =
  readNonZeroNaturalNumber(process?.env.TURNSTILE_THRESH, 0) ?? 0;

export const DUMMY_RECORDING_ID =
  process.env.DUMMY_RECORDING_ID ??
  "05f2a0ab-8a49-4783-8e41-92a16513959e/de9d9b51-b006-4195-9004-beb6f4d2cfda/1970-01-01T00:00.MP4";

/**
 * How long until the presigned URL expires.
 * User's browser must download and transcode the video within this time period.
 * The current value is 3 hours in seconds.
 * The maxiumum allowable value is 1 week in seconds.
 *
 * See also: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
 */
export const S3_PRESIGNED_TIMEOUT_SECONDS =
  readNonZeroNaturalNumber(process.env.S3_PRESIGNED_TIMEOUT_SECONDS) ??
  60 * 60 * 3;

export const SEARCH_LISTINGS_RATE_LIMIT_PER_MINUTE: number | undefined =
  isBrowser
    ? OUTSCHOOL_SERVER.SEARCH_LISTINGS_RATE_LIMIT_PER_MINUTE
    : readNonZeroNaturalNumber(
        process.env.SEARCH_LISTINGS_RATE_LIMIT_PER_MINUTE
      );

export const CPU_PROFILE_SERVER_RATIO = process.env.CPU_PROFILE_SERVER_RATIO;
export const CPU_PROFILE_GQL_QUERY_NAMES =
  process.env.CPU_PROFILE_GQL_QUERY_NAMES;
export const CPU_PROFILE_DELAY_THRESHOLD =
  process.env.CPU_PROFILE_DELAY_THRESHOLD;

export const USE_PRODUCT_FEED_EXPORT_V2 =
  process.env.USE_PRODUCT_FEED_EXPORT_V2 || false;

export const GOOGLE_AUTH_DISALLOW_WEBVIEW = isBrowser
  ? OUTSCHOOL_SERVER.GOOGLE_AUTH_DISALLOW_WEBVIEW
  : process.env.FF_GOOGLE_AUTH_DISALLOW_WEBVIEW === "enabled";

// A comma seperated list of 2 letter ISO country codes
export const REQUEST_3DS_COUNTRIES =
  process.env?.REQUEST_3DS_COUNTRIES?.split(",") ?? [];

export const USE_PAYOUTS_REVERSE_LOOKUP =
  isTest ||
  isDevelopment ||
  process.env.FF_USE_PAYOUTS_REVERSE_LOOKUP === "enabled";

export const CONSIDER_STOPPED_ALLOW_RESUME_ACTIVE =
  isTest ||
  isDevelopment ||
  process.env.CONSIDER_STOPPED_ALLOW_RESUME_ACTIVE === "enabled";

export const PAUSE_PAYMENT_FAILURES =
  process.env.PAUSE_PAYMENT_FAILURES === "enabled";

export const USE_V2_AUTH_EVERYWHERE =
  process.env.FF_USE_V2_AUTH_EVERYWHERE === "enabled";

export const INTERNAL_HOST_REDIRECT =
  process.env.INTERNAL_HOST_REDIRECT === "enabled";

// Enables sending errors to Sentry
// This should only be used for debugging purposes in non-prod environments
export const SENTRY_ENABLED = process.env.SENTRY_ENABLED === "true";
export const SENTRY_DSN = process.env.SENTRY_DSN;

// Waitlist Environment Variables

// Safety hatch if waitlist seats are affecting enrollments
export const UPDATE_WAITLIST_SEATS_ON_ENROLLMENT =
  process.env.UPDATE_WAITLIST_SEATS_ON_ENROLLMENT === "true";

export const WAITLIST_EMAILS_ENABLED =
  process.env.WAITLIST_EMAILS_ENABLED === "true";

/**
 * GQL MIDDLEWARE VARIABLES
 */
export const GQL_MIDDLEWARE_ENABLED =
  process.env.GQL_MIDDLEWARE_ENABLED === "enabled";
/**
 * Graphql operation name that will block until an email is confirmed
 */
export const GQL_EMAIL_CONFIRMATION_BLACKLIST =
  process.env.GQL_BLACKLIST?.split(",") || [];

/**
 * Users without a confirmed email that created an account before the
 * grandfather date will by-pass the email confirmed block above
 */
export const GRANDFATHER_DATE = process.env.GRANDFATHER_DATE;

export const IS_READ_ONLY_MODE = isBrowser
  ? OUTSCHOOL_SERVER.IS_READ_ONLY_MODE
  : process.env.READ_ONLY_MODE === "enabled";

/**
 * These vars control whether we log backend API calls to our Trust&Safety
 * log stream.
 */
export const TS_LOGGING_ENABLED =
  process.env.FF_TS_LOGGING_ENABLED === "enabled";
export const TS_LOGGING_QUERY_WHITELIST = new Set(
  (process.env.TS_LOGGING_QUERY_WHITELIST || "").split("|")
);
export const TS_LOGGING_MUTATION_BLACKLIST = new Set(
  (process.env.TS_LOGGING_MUTATION_BLACKLIST || "").split("|")
);

/**
 * Trust & Safety Ops Genie API key
 */
export const TS_OPS_GENIE_API_KEY = process.env.TS_OPS_GENIE_API_KEY || "";

/**
 * Enables an experiment to test the impact of requiring email confirmation to
 * purchase classes.
 */
export const SKIP_EMAIL_CONFIRMATION_FOR_PAYMENTS_EXP_ENABLED =
  isDevelopment ||
  isTest ||
  process.env.FF_SKIP_EMAIL_CONFIRMATION_FOR_PAYMENTS_EXP_ENABLED === "enabled";

export const LINE_CLIENT_ID_JA = process.env.LINE_CLIENT_ID_JA;
export const LINE_CLIENT_SECRET_JA = process.env.LINE_CLIENT_SECRET_JA;
export const LINE_CLIENT_ID_TW = process.env.LINE_CLIENT_ID_TW;
export const LINE_CLIENT_SECRET_TW = process.env.LINE_CLIENT_SECRET_TW;

export const KAKAO_CLIENT_ID = process.env.KAKAO_CLIENT_ID;
export const KAKAO_CLIENT_SECRET = process.env.KAKAO_CLIENT_SECRET;

export const STRIPE_FINGERPRINT_SHARING_LIMIT = isTest
  ? Number.parseInt(process.env.STRIPE_FINGERPRINT_SHARING_LIMIT || "2")
  : Number.parseInt(process.env.STRIPE_FINGERPRINT_SHARING_LIMIT || "0");

export const STRIPE_FINGERPRINT_BLOCKS_MAX_OTHER_CREDITS_COUNT =
  Number.parseInt(
    process.env.STRIPE_FINGERPRINT_BLOCKS_MAX_OTHER_CREDITS_COUNT || "0"
  );

// Default to enabled (non zero) in all envs but prod
export const TNS_POINTS_SUSPENSION_THRESHOLD = isBrowser
  ? OUTSCHOOL_SERVER.TNS_POINTS_SUSPENSION_THRESHOLD
  : Number.parseInt(
      process.env.TNS_POINTS_SUSPENSION_THRESHOLD || (isProduction ? "0" : "3")
    );

export const TEACHER_SITE_API_KEY = process.env.TEACHER_SITE_API_KEY;

export const LOG_OTHER_ZOOM_WEBHOOKS = Boolean(
  process.env.LOG_OTHER_ZOOM_WEBHOOKS
);
