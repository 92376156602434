import { Component, Priority } from "@outschool/ownership-areas";
import { init } from "@outschool/ui-analytics";

import {
  COMMIT_SHA,
  KAFKA_REST_PROXY,
  OUTSCHOOL_ENV,
  SEGMENT_WRITE_KEY,
  UI_ANALYTICS_DEBUG,
  UI_ANALYTICS_DEV_MODE,
  UI_ANALYTICS_DISABLED_INTEGRATION_LIST,
  UI_ANALYTICS_EVENT_QUEUE_LIMIT,
  UI_ANALYTICS_LOAD_TIME_LIMIT,
  isProduction
} from "../../shared/Env";

export function initAnalyticsPackage() {
  if (!SEGMENT_WRITE_KEY) {
    return;
  }
  init({
    app: "website",
    debug: UI_ANALYTICS_DEBUG === "enabled",
    devMode: UI_ANALYTICS_DEV_MODE,
    disabledIntegrationList: UI_ANALYTICS_DISABLED_INTEGRATION_LIST,
    env: OUTSCHOOL_ENV,
    eventQueueLimit: Number(UI_ANALYTICS_EVENT_QUEUE_LIMIT) || undefined,
    gitCommitId: COMMIT_SHA,
    loadTimeLimit: Number(UI_ANALYTICS_LOAD_TIME_LIMIT) || undefined,
    onError,
    performance: getPerformanceConfig(),
    segmentWriteKey: SEGMENT_WRITE_KEY,
    kafkaRestProxy: KAFKA_REST_PROXY
  });
}

function getPerformanceConfig() {
  const pages = [
    {
      name: "catalog-hub"
    },
    {
      name: "category"
    },
    {
      name: "class"
    },
    {
      name: "group"
    },
    {
      name: "enrolled-page"
    },
    {
      name: "home"
    },
    {
      name: "search"
    },
    {
      name: "teacher-profile"
    }
  ];

  const observers = [
    { type: "first-input" },
    { type: "largest-contentful-paint" },
    { type: "layout-shift" },
    {
      type: "measure",
      label: "os-page-load",
      pages: pages.map(page => page.name)
    }
  ];

  return {
    observers,
    pages
  };
}

function onError(error: Error) {
  OsPlatform.captureError(error, {
    tags: {
      component: Component.Tracking,
      priority: Priority.P3
    }
  });

  /*
   * console.log rather than console.error to avoid double event reporting to
   * Sentry.
   */
  if (!isProduction) {
    console.log(error);
  }
}
