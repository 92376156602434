import { Box, Button } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import React from "react";

const LocalizationPickerMenuApplyAndCancel = ({
  isOpen,
  handleClickCancel,
  handleClickApply,
  applyIsLoading
}: $TSFixMe) => {
  const { t } = useTranslation(
    "client\\components\\localization\\LocalizationPickerMenuApplyAndCancel"
  );
  return (
    <Box
      flex
      sx={{
        justifyContent: "space-between",
        padding: "15px"
      }}
    >
      <Button
        variant="link"
        tabIndex={isOpen ? 0 : -1}
        onClick={handleClickCancel}
      >
        {t`Cancel`}
      </Button>
      <Button
        variant="contained"
        tabIndex={isOpen ? 0 : -1}
        disabled={!!applyIsLoading}
        sx={{
          minWidth: "90px",
          maxHeight: "45px"
        }}
        onClick={handleClickApply}
      >
        {t`Apply Changes`}
      </Button>
    </Box>
  );
};

export default LocalizationPickerMenuApplyAndCancel;
