// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useLocalStorageState } from "@outschool/local-storage";
import { sellerOrgPath, teacherDashboardPath } from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import React, { PropsWithChildren, useContext, useEffect } from "react";
import { useLocation } from "react-router";

import { FeatureFlag } from "../../shared/Env";
import * as Routes from "../../shared/Routes";
import { UserMode } from "../../shared/UserMode";
import { getOrgAdminRoutesFromUser, isBuyerOrgAdmin } from "./BuyerOrgAdmins";
import { useFeatureFlag } from "./FeatureFlags";

export { UserMode };

const LOCAL_STORAGE_KEY = "LastUserMode";

const UserModeContext = React.createContext<UserMode>(UserMode.Parent);
/**
 * The last visited user mode.
 */
export const useLastUserMode = () => {
  return useLocalStorageState<string | null>(LOCAL_STORAGE_KEY, null);
};

/**
 * Provide a user mode value for all child components.
 * Every page is in one of these modes. We default to Parent Mode unless
 *   a) a page opts into Educator mode by setting that prop on its SimpleLayout or
 *   b) the page's path starts with /teach
 */
export const UserModeProvider: React.FC<
  PropsWithChildren<{
    value: UserMode | null;
  }>
> = props => {
  const { pathname } = useLocation();
  const [lastUserMode, setLastUserMode] = useLastUserMode();
  const { currentUser } = useSession();
  const isOrgAdmin = !!currentUser?.sellerOrg?.currentUserIsOwner;

  let userMode: UserMode | null;
  // For now, organization admins get the parent experience with a few extra
  // toolbar items.
  if (isOrgAdmin) {
    userMode = UserMode.Parent;
  } else if (!props.value) {
    const isTeachPath =
      pathname.startsWith(Routes.teachBasePath()) ||
      pathname.startsWith(sellerOrgPath());
    userMode = isTeachPath ? UserMode.Educator : UserMode.Parent;
  } else {
    userMode = props.value;
  }

  useEffect(() => {
    if (userMode !== lastUserMode) {
      setLastUserMode(userMode);
    }
  }, [userMode, lastUserMode, setLastUserMode]);

  return (
    <UserModeContext.Provider value={userMode}>
      {props.children}
    </UserModeContext.Provider>
  );
};

/**
 * Consume the user mode of the current page.
 * The second return value is a function which redirects to the root page for
 * the given mode.
 */
export const useUserMode = () =>
  // Fallback to Parent mode
  useContext(UserModeContext) ?? UserMode.Parent;

/** Consume the root path for the `UserMode` of the current page. */
export function useUserModeRootPath(): string {
  const userMode = useUserMode();
  const { currentUser } = useSession();
  const buyerOrgAdminModeEnabled = useFeatureFlag(
    FeatureFlag.BuyerOrgAdminMode
  );
  const isBuyerOrgAdminMode =
    buyerOrgAdminModeEnabled && isBuyerOrgAdmin(currentUser);

  const { homeRoute: orgAdminHomeRoute } =
    getOrgAdminRoutesFromUser(currentUser);

  let rootPath: string;
  if (isBuyerOrgAdminMode && orgAdminHomeRoute) {
    rootPath = orgAdminHomeRoute;
  } else {
    rootPath = userModeRootPath(userMode);
  }

  return rootPath;
}

/** The root path for a particular `UserMode` */
export function userModeRootPath(userMode: UserMode): string {
  if (userMode === UserMode.Educator) {
    return teacherDashboardPath();
  } else {
    return "";
  }
}
