import { Box, Typography } from "@outschool/backpack";
import { sellerOrgClassLinksPath } from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { NavTabs } from "@outschool/ui-components-shared";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import * as Routes from "../../../shared/Routes";
import PageTools from "../lib/PageTools";

export default function SellerOrgManageToolbar() {
  const { currentUser } = useSession();

  return (
    <PageTools>
      <Box>
        <Typography
          variant="h2"
          sx={{
            marginBottom: "0.5em"
          }}
        >
          Manage {currentUser?.name ?? "Organization"}
        </Typography>
      </Box>
      <NavTabs.TabList>
        <NavTabs.Tab label="Dashboard" to={Routes.sellerOrgDashboardPath()} />
        <NavTabs.Tab label="Schedule" to={Routes.sellerOrgSchedulePath()} />
        <NavTabs.Tab label="Teachers" to={Routes.sellerOrgTeachersPath()} />
        <NavTabs.Tab label="Classes" to={Routes.sellerOrgClassesPath()} />
        <NavTabs.Tab label="Class Links" to={sellerOrgClassLinksPath()} />
        <NavTabs.Tab label="Groups" to={Routes.sellerOrgClubsPath()} />
        <NavTabs.Tab label="Sections" to={Routes.sellerOrgSectionsPath()} />
        <NavTabs.Tab label="Profile" to={Routes.sellerOrgProfilePath()} />
        <NavTabs.Tab label="Stats" to={Routes.sellerOrgStatsPath()} />
        <NavTabs.Tab label="Operations" to={Routes.sellerOrgOperationsPath()} />
        <NavTabs.Tab
          label="Credentials"
          to={Routes.sellerOrgCredentialsPath()}
        />
      </NavTabs.TabList>
    </PageTools>
  );
}
