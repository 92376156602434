import { Box, Icon, Tooltip, Typography } from "@outschool/backpack";
import { faInfoCircle } from "@outschool/icons";
import * as Localization from "@outschool/localization";
import {
  SupportedLanguage,
  Trans,
  getLanguageFromLocale,
  useLocale,
  useTranslation
} from "@outschool/localization";
import { parentSettingsPath } from "@outschool/routes";
import { useTopNoticeContext } from "@outschool/ui-components-website";
import {
  Loading,
  useImpressionTracking
} from "@outschool/ui-legacy-component-library";
import { useIsTablet } from "@outschool/ui-utils";
import React, { useEffect, useState } from "react";

import { ENABLE_LOCALE_PSEUDOLOCALIZATION } from "../../../shared/Env";
import { CurrencyCode } from "../../../shared/generated/graphql";
import { usePseudoLocalization, useSetLocale } from "../../hooks/Localization";
import { shouldChargeInLocalCurrency } from "../../lib/Localization";
import { useTimeZone } from "../../providers/TimeZoneProvider";
import Link from "../Link";
import CurrencySelector from "./CurrencySelector";
import LanguageSelector from "./LanguageSelector";
import LocalizationPickerMenuApplyAndCancel from "./LocalizationPickerMenuApplyAndCancel";
import PseudoLocalizationControl from "./PseudoLocalizationControl";
import TimeZoneSelector from "./TimeZoneSelector";

interface LocalizationPickerMenuProps {
  isOpen?: boolean;
  onClose?: () => void;
  heading?: string;
}

const trackingLabelApply = "localization_picker_menu_apply";
const trackingLabelCancel = "localization_picker_menu_cancel";

const headingSx = {
  margin: "15px 0 10px 0"
};

export default function LocalizationPickerMenu({
  isOpen,
  onClose,
  heading
}: LocalizationPickerMenuProps) {
  const { t } = useTranslation(
    "client\\components\\localization\\LocalizationPickerMenu"
  );
  heading ??= t`Update Regional Settings`;
  const { setCurrency } = Localization.useSetCurrencyLocalization();
  const { currencyCode } = Localization.useCurrencyLocalization();
  const { timeZone, setTimeZone } = useTimeZone();
  const locale = useLocale();
  const language = getLanguageFromLocale(locale);

  const { setLocale } = useSetLocale();
  const { togglePseudo, pseudoRepeatCount, updatePseudoLocalizationSettings } =
    usePseudoLocalization();

  const [selectedCurrency, setSelectedCurrency] =
    useState<CurrencyCode>(currencyCode);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(timeZone);
  const [selectedLanguage, setSelectedLanguage] =
    useState<SupportedLanguage>(language);
  const [togglePseudolocalization, setTogglePseudolocalization] =
    useState(togglePseudo);
  const [pseudolocalizationRepeatCount, setPseudolocalizationRepeatCount] =
    useState(pseudoRepeatCount);

  const [applyIsLoading, setApplyIsLoading] = useState<Boolean>(false);

  const isTablet = useIsTablet();

  useEffect(() => {
    setSelectedCurrency(currencyCode);
  }, [currencyCode]);

  const isChargingInLocalCurrency =
    shouldChargeInLocalCurrency(selectedCurrency);

  const [error, setError] = useState<string | null>(null);

  const { setTimedTopNotice } = useTopNoticeContext();

  useEffect(() => {
    setTimedTopNotice(error);
  }, [setTimedTopNotice, error]);

  const platformView = isTablet ? "mobile" : "desktop";

  const { trackTouch: trackApplyTouch } = useImpressionTracking({
    trackingLabel: trackingLabelApply,
    uniqueId: trackingLabelApply,
    sharedProperties: {
      currencyCode: selectedCurrency,
      timeZone: selectedTimeZone,
      platformView
    }
  });

  const { trackTouch: trackCancelTouch } = useImpressionTracking({
    trackingLabel: trackingLabelCancel,
    uniqueId: trackingLabelCancel,
    sharedProperties: {
      platformView
    }
  });

  const onApply = async () => {
    try {
      setApplyIsLoading(true);
      await setCurrency(selectedCurrency);
      await setTimeZone(selectedTimeZone);
      // Call this last, since it may redirect the page
      setLocale(selectedLanguage.i18nLocale);
      // updates context and i18n with local state
      updatePseudoLocalizationSettings(
        togglePseudolocalization,
        pseudolocalizationRepeatCount
      );
      setApplyIsLoading(false);
    } catch (err) {
      setError(err);
      console.error(err);
    }
  };

  const cancel = () => {
    setSelectedCurrency(currencyCode);
  };

  const handleClickApply = async () => {
    trackApplyTouch();
    await onApply();
    onClose?.();
  };

  const handleClickCancel = () => {
    cancel();
    onClose?.();
    trackCancelTouch();
  };

  const subheadText = t`Select the language and currency you use to browse on Outschool.`;

  return (
    <>
      <Box
        sx={{
          padding: "15px"
        }}
        id="localization-picker-menu"
      >
        <Typography
          variant="h3"
          sx={{
            marginBottom: "10px"
          }}
        >
          {heading}
        </Typography>
        <Typography
          variant="inherit"
          sx={{
            display: "inline-block",
            maxWidth: "330px"
          }}
        >
          {subheadText}
        </Typography>
        <React.Suspense fallback={<Loading />}>
          <Typography variant="h4" sx={headingSx}>
            {t`Language`} <LanguageTooltip t={t} />
          </Typography>
          <LanguageSelector
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </React.Suspense>
        <Typography variant="h4" sx={headingSx}>
          {t`Currency`}
        </Typography>
        <CurrencySelector
          hideCurrencyDisclaimer={isChargingInLocalCurrency}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
        />
        <React.Suspense fallback={<Loading />}>
          <Typography variant="h4" sx={headingSx}>
            {t`Time Zone`}
          </Typography>
          <TimeZoneSelector
            selectedTimeZone={selectedTimeZone}
            setSelectedTimeZone={setSelectedTimeZone}
          />
        </React.Suspense>
      </Box>
      {ENABLE_LOCALE_PSEUDOLOCALIZATION && (
        <Box
          sx={{
            padding: "15px",
            boxShadow: "0 0 1px 3px rgba(0, 0, 0, .125)",
            bg: "#F0F3FF"
          }}
          id="toggle_pseudolocalization"
        >
          <PseudoLocalizationControl
            contextToggleValue={togglePseudo}
            togglePseudolocalization={togglePseudolocalization}
            pseudolocalizationRepeatCount={pseudolocalizationRepeatCount}
            setTogglePseudolocalization={setTogglePseudolocalization}
            setPseudolocalizationRepeatCount={setPseudolocalizationRepeatCount}
          />
        </Box>
      )}
      <LocalizationPickerMenuApplyAndCancel
        isOpen={isOpen}
        handleClickApply={handleClickApply}
        handleClickCancel={handleClickCancel}
        applyIsLoading={applyIsLoading}
      />
    </>
  );
}

const LanguageTooltip = ({ t }: $TSFixMe) => (
  <Tooltip
    placement="top"
    title={
      <Typography
        variant="inherit"
        sx={{
          fontSize: "initial",
          fontWeight: "fontWeightRegular"
        }}
      >
        <Trans t={t}>
          The language the site is currently displayed in. To update your
          default language for emails,{" "}
          <Link to={parentSettingsPath()} reloadDocument={true}>
            visit your settings page
          </Link>
          .
        </Trans>
      </Typography>
    }
  >
    <Icon
      icon={faInfoCircle}
      color="primary"
      sx={{
        fontSize: "0.7em"
      }}
      tabIndex={0}
    />
  </Tooltip>
);
