import { useTranslation } from "@outschool/localization";
import React from "react";

import PageModal from "../PageModal";
import LocalizationPickerMenu from "./LocalizationPickerMenu";

const LocalizationPickerModal = ({ open, onClose }: $TSFixMe) => {
  const { t } = useTranslation(
    "client\\components\\localization\\LocalizationPickerModal"
  );
  const heading = t`Update Regional Settings`;

  return (
    <PageModal
      open={open}
      onClose={onClose}
      ariaLabel={heading}
      sx={{ maxWidth: 500, maxHeight: "100vh" }}
    >
      <LocalizationPickerMenu
        isOpen={true}
        onClose={onClose}
        heading={heading}
      />
    </PageModal>
  );
};

export default LocalizationPickerModal;
