import { Box } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import {
  addParamsToUrl,
  joinParentAccountPath,
  joinTeacherAccountPath,
  loginPath,
  queryStringToObject,
  teachPath
} from "@outschool/routes";
import { useAnalytics } from "@outschool/ui-analytics";
import {
  ContinueWithApple,
  ContinueWithGoogle,
  ContinueWithKakao,
  ContinueWithLine,
  LoginUserFeedback,
  SimplifiedEmailSignupForm,
  getPostLoginPath,
  useAuthError,
  useCanShowGoogleAuth,
  useShouldShowKakao,
  useShouldShowLine
} from "@outschool/ui-auth";
import { AccountDisclaimer } from "@outschool/ui-components-shared";
import {
  EmailSignupButton,
  useSimplifyEmailSignupExperiment
} from "@outschool/ui-components-website";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";

import { APPLE_SIGNIN_CLIENT_ID, GOOGLE_CLIENT_ID } from "../../shared/Env";
import ActionType from "../actions/ActionType";
import {
  useLoginWithApple,
  useLoginWithRedirect
} from "../hooks/Authentication";
import { useAppState } from "../stores/AppStateProvider";
import Link from "./Link";

interface SignupFormProps {
  onRedirect?: (e: MouseEvent) => void;
  onClickSocialSignup?: () => void;
  promptLogin?: boolean;
  isTeacherFlow?: boolean;
  trackingParameters?: { [key: string]: string };
  trackingUniqueId?: string;
  signupParams?: { [key: string]: string | boolean | number };
  loginParams?: { [key: string]: string | boolean | number };
  // Below parameters used in SimplifyEmailSignup experiment
  setLoadForSimplifyEmailExp?: (isLoading: boolean) => void;
  includeSimplifyEmailSignupExperiment?: boolean;
}

export default function SignupForm({
  onRedirect,
  onClickSocialSignup,
  promptLogin,
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  signupParams,
  loginParams,
  // Below parameters used in SimplifyEmailSignup experiment
  setLoadForSimplifyEmailExp,
  includeSimplifyEmailSignupExperiment = false
}: SignupFormProps) {
  const postLoginPath = getPostLoginPath();
  const { t } = useTranslation("client\\components\\SignupForm");

  // Conditionally show email signup form for SimplifyEmailSignup experiment
  const [showEmailSignupForm, setShowEmailSignupForm] = useState(false);
  return (
    <Box
      sx={{
        display: "grid",
        gap: "1em",
        width: "100%"
      }}
    >
      <SignupButtons
        isTeacherFlow={isTeacherFlow}
        trackingParameters={trackingParameters}
        trackingUniqueId={trackingUniqueId}
        onRedirect={onRedirect}
        signupParams={signupParams}
        postLoginPath={postLoginPath}
        onClickSocialSignup={onClickSocialSignup}
        // Below parameters used in SimplifyEmailSignup experiment for loading and UI state
        includeSimplifyEmailSignupExperiment={
          includeSimplifyEmailSignupExperiment
        }
        setLoadForSimplifyEmailExp={setLoadForSimplifyEmailExp}
        setShowEmailSignupForm={setShowEmailSignupForm}
        showEmailSignupForm={showEmailSignupForm}
      />
      {promptLogin ? (
        <Trans t={t}>
          <Box
            sx={{
              justifySelf: "center"
            }}
          >
            Already have an account?{" "}
            <Link
              data-test-id="login-link"
              onClick={onRedirect}
              to={addParamsToUrl(loginPath(), {
                postLoginPath: loginParams?.redirect ?? postLoginPath,
                ...loginParams
              })}
              trackingName="login"
              trackingUniqueId={trackingUniqueId}
            >
              Log in
            </Link>
          </Box>
        </Trans>
      ) : null}
      <AccountDisclaimer
        isSignup={true}
        isSimplifyEmailExperimentShown={showEmailSignupForm}
      />
    </Box>
  );
}

function SignupButtons({
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  onRedirect,
  signupParams,
  postLoginPath,
  onClickSocialSignup,
  // Below parameters used in SimplifyEmailSignup experiment
  includeSimplifyEmailSignupExperiment,
  setLoadForSimplifyEmailExp,
  setShowEmailSignupForm,
  showEmailSignupForm
}: $TSFixMe) {
  const analytics = useAnalytics();
  const { t } = useTranslation("client\\components\\SignupForm");
  const history = useHistory();
  const [userFeedbackMessage, setUserFeedbackMessage] =
    React.useState<React.ReactNode>(null);
  const appState = useAppState();
  const canShowGoogleAuth = useCanShowGoogleAuth();

  const {
    isGiftCardSignup,
    isOnboardingSignup,
    shouldCreateDefaultLearner,
    defaultLearnerAge
  } = signupParams || {};

  const createParams = {
    isGiftCardSignup,
    isLeader: isTeacherFlow,
    trackingParameters,
    isOnboardingSignup,
    shouldCreateDefaultLearner,
    defaultLearnerAge
  };

  const defaultErrorMessage = t("Sorry that didn't work, try again");
  const handleAuthError = React.useCallback(
    (error: $TSFixMe, message: React.ReactNode = defaultErrorMessage) => {
      OsPlatform.captureError(error, {
        tags: { component: Component.UserAccountManagement }
      });
      setUserFeedbackMessage(message);
    },
    [defaultErrorMessage, setUserFeedbackMessage]
  );

  const generateLoginError = useAuthError();

  const handleLoginSuccess = React.useCallback(
    ({
      sessionToken,
      refreshToken,
      postLoginPath,
      isLearnerTransfer
    }: $TSFixMe) => {
      if (!isLearnerTransfer) {
        appState.appDispatcher.dispatch(ActionType.User.LOGGED_IN, {
          sessionToken,
          refreshToken
        });
        const redirectPath =
          signupParams?.redirect ||
          (isTeacherFlow ? teachPath() : postLoginPath);
        history.push(redirectPath);
      } else {
        appState.appDispatcher.dispatch(
          ActionType.User.REDIRECT_TO_LEARNER_APP,
          {
            transferToken: sessionToken,
            isLearnerLogin: true
          }
        );
      }
    },
    [history, appState.appDispatcher, isTeacherFlow, signupParams?.redirect]
  );

  const handleLoginError = React.useCallback(
    (error: $TSFixMe) => {
      const errorObject = new Error(error);
      handleAuthError(errorObject, generateLoginError(error));
    },
    [handleAuthError, generateLoginError]
  );

  const handleAppleLogin = useLoginWithApple(
    handleLoginSuccess,
    handleLoginError,
    createParams,
    postLoginPath
  );

  const setAuthStrategy = useLoginWithRedirect(
    handleLoginError,
    createParams,
    // @ts-ignore TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    null,
    signupParams?.redirect || postLoginPath
  );

  const showKakao = useShouldShowKakao();
  const showLine = useShouldShowLine();

  /* Code added for SimplifyEmailSignup experiment */
  const { isInTreatment, trigger } = useSimplifyEmailSignupExperiment();
  const location = useLocation();
  const handleSubmitClick = (loading: boolean) => {
    setShowEmailSignupForm(!loading);
  };
  const searchParams =
    (typeof window !== "undefined" && queryStringToObject(location.search)) ||
    {};
  /* End of code added for SimplifyEmailSignup experiment */

  return (
    <React.Fragment>
      <LoginUserFeedback
        userFeedbackMessage={userFeedbackMessage}
        isError={true}
      />
      {canShowGoogleAuth && (
        <ContinueWithGoogle
          trackingName="signup-with-google"
          setAuthStrategy={setAuthStrategy}
          // @ts-ignore TS(2322): Type '() => Promise<string | undefined>' is not as... Remove this comment to see the full error message
          getAnonymousId={analytics.anonymousId}
          clientId={GOOGLE_CLIENT_ID!}
          onClick={onClickSocialSignup}
        />
      )}

      {showLine && (
        <ContinueWithLine
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-line"
          getAnonymousId={analytics.anonymousId}
        />
      )}

      {showKakao && (
        <ContinueWithKakao
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-kakao"
          getAnonymousId={analytics.anonymousId}
        />
      )}

      <ContinueWithApple
        onSuccess={handleAppleLogin}
        onError={handleAuthError}
        trackingName="signup-with-apple"
        clientId={APPLE_SIGNIN_CLIENT_ID!}
        onClick={onClickSocialSignup}
      />
      {isInTreatment &&
      !isTeacherFlow &&
      includeSimplifyEmailSignupExperiment ? (
        <SimplifiedEmailSignupForm
          trigger={trigger}
          showEmailSignupForm={showEmailSignupForm}
          setShowEmailSignupForm={setShowEmailSignupForm}
          handleSubmitClick={handleSubmitClick}
          setLoadForSimplifyEmailExp={setLoadForSimplifyEmailExp}
          searchParams={searchParams}
          trackingUniqueId={trackingUniqueId}
          onUserLoggedIn={(sessionToken: string, refreshToken: string) => {
            appState.appDispatcher.dispatch(ActionType.User.LOGGED_IN, {
              sessionToken,
              refreshToken
            });
          }}
          onUserRefresh={() => {
            appState.appDispatcher.dispatch(ActionType.User.REFRESH);
          }}
        />
      ) : (
        <EmailSignupButton
          linkComponent={Link}
          onClick={() => {
            onRedirect;
            if (includeSimplifyEmailSignupExperiment) {
              // Trigger SimplifyEmailSignup experiment if it is not excluded
              trigger();
            }
          }}
          trackingUniqueId={trackingUniqueId}
          to={
            isTeacherFlow
              ? joinTeacherAccountPath()
              : joinParentAccountPath({ ...signupParams, postLoginPath })
          }
        />
      )}
    </React.Fragment>
  );
}
