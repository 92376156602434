import { getReferrerAttribution } from "@outschool/attribution";
import { useQuery } from "@outschool/ui-apollo";
import {
  TopBarPromoBanner,
  canUserRedeemCouponQuery
} from "@outschool/ui-components-website";
import React, { useEffect } from "react";
import { useLocation } from "react-router";

import useExperiment from "../../hooks/useExperiment";

const coupon = {
  code: "STARTSTRONG20",
  uid: "b281c049-0dd8-4446-b8b1-1dc81b9c863d"
};

export const TopBarPromoBannerExperiment = () => {
  const { utm_medium } = getReferrerAttribution();
  const isAdTraffic = !!utm_medium && utm_medium.startsWith("ads");

  const { pathname } = useLocation();
  const isCategoryPage = pathname.startsWith("/online-classes/");

  const { variant, trigger } = useExperiment("PromoBannerAds20");

  useEffect(() => {
    if (isAdTraffic && isCategoryPage) {
      trigger();
    }
  }, [trigger, isAdTraffic, isCategoryPage]);

  if (!isAdTraffic || variant != "treatment" || !isCategoryPage) {
    return null;
  }
  return <PromoBannerWithDataFetch />;
};

const PromoBannerWithDataFetch = () => {
  const { data } = useQuery(canUserRedeemCouponQuery, {
    variables: { couponUid: coupon.uid },
    initialFetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only"
  });
  return data ? (
    <TopBarPromoBanner data={data} couponCode={coupon.code} />
  ) : null;
};
